var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "height": "".concat(_vm.height) || '20',
      "width": "".concat(_vm.width) || '20',
      "viewBox": "".concat(_vm.viewBox) || '0 0 20 20',
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": function click($event) {
        return _vm.action();
      }
    }
  }, _vm._l(_vm.paths, function (item, index) {
    return _c('path', {
      key: index,
      attrs: {
        "d": item.d,
        "fill": item.fill,
        "fill-rule": item.fillRule,
        "clip-rule": item.clipRule,
        "stroke": item.stroke,
        "stroke-width": item.strokeWidth,
        "stroke-miterlimit": item.strokeMiterlimit,
        "stroke-linecap": item.strokeLinecap,
        "stroke-linejoin": item.strokeLinejoin
      }
    });
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }